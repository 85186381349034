<template>
<div class="wrapper">
    <ul class="bg-bubbles">
            <li v-for="n in 10" :key="n+'n'"></li>
            <ol v-for="m in 5"  :key="m+'m'"></ol>
    </ul>
    <div class="bg bg-blur" style="display: none;"></div>
        <div class="login_box">
          <!--logo-->
          <div class="login_img">
            <img src="../assets/logo.png" />
          </div>
          <!--logoform-->
          <el-form ref="loginFormRef" :rules="loginFormRules" class="login_form" status-icon :model="loginForm" >
            <!--logouser-->
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" clearable placeholder="Username"  prefix-icon="el-icon-s-custom"></el-input>
            </el-form-item>
            <!--logopassword-->
            <el-form-item prop="password" >
              <el-input v-model="loginForm.password"  placeholder="Password" show-password prefix-icon="el-icon-s-cooperation"  @keyup.enter.native="login" type="password"></el-input>
            </el-form-item>
            <!--logobutton-->
            <el-form-item >
            <div class="login_butn">
              <el-button type="primary" @click="login">Login</el-button>
              <el-button type="info" @click="resetLoginForm">Reset</el-button>
            </div>
            </el-form-item>
          </el-form>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      // loginForm数据
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 3, max: 10, message: '账号不少于三位，不大于十位', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 15, message: '密码不少于三位，不大于十位', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
  computed: {

  },
  methods: {
    ...mapMutations(['loginlist']),
    // 重置表单
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    // 登录数据校验判断用户密码
    // 返回值是promise 可以用await简化操作，注意await只能用在async方法中
    login () {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$axios.post('http://api.nienyi.cn/api/Authenticate/login', this.loginForm)
        if (res.status !== 200) return this.$message.error('登陆失败，账号或密码错误！')
        this.$message.success('登陆成功')
        this.loginlist(res.data)
        window.sessionStorage.setItem('token', res.token)
        this.$router.push('/NienyiMain')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
        margin: 0px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(../assets/loginbck.png) no-repeat top left;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
}
    .wrapper {
        background: #50a3a2;
        background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
        background: linear-gradient(to bottom right, #a8e0df 0, #1446fd);
        opacity: 0.8;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .wrapper.form-success .containerLogin h1 {
        -webkit-transform: translateY(85px);
        -ms-transform: translateY(85px);
        transform: translateY(85px);
    }
.login_box{
  width: 450px;
  height: 300px;
  background-color: #fff;
  //background-color:rgba(0, 183, 255, 0.993);
  border-radius: 3px;
  position: absolute; //绝对定位
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%); //居中用的
}
.login_img{
  height: 170px;
  width: 170px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 6%;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%,-50%);//根据这个div的本身长宽高来唯一，前面是左右，后面是上下

}
img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}
.login_form{
position:absolute;
bottom: 0;
width: 100%;
padding: 0 20px;
box-sizing: border-box;
}
.login_butn{
  display:flex;
  justify-content:flex-end;
  float:right;
}
.bg-bubbles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .bg-bubbles li, .bg-bubbles ol {
        position: absolute;
        list-style: none;
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.15);
        bottom: -160px;
        -webkit-animation: square 25s infinite;
        animation: square 25s infinite;
        -webkit-transition-timing-function: linear;
        transition-timing-function: linear;
    }

    ol {
        padding: 0 !important;
    }

    .bg-bubbles ol:nth-child(11) {
        left: 10%;
        top: 10%;
        width: 20px;
        height: 20px;
    }

    .bg-bubbles ol:nth-child(12) {
        left: 20%;
        top: 40%;

        width: 60px;
        height: 60px;
    }

    .bg-bubbles ol:nth-child(13) {
        left: 65%;
        top: 30%;

        width: 100px;
        height: 60px;
    }

    .bg-bubbles ol:nth-child(14) {
        left: 70%;
        top: 30%;
        width: 100px;
        height: 150px;
    }

    .bg-bubbles ol:nth-child(15) {
        left: 50%;
        top: 70%;

        width: 40px;
        height: 60px;
    }

    .bg-bubbles li:nth-child(1) {
        left: 10%;
    }

    .bg-bubbles li:nth-child(2) {
        left: 20%;
        width: 80px;
        height: 80px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-duration: 17s;
        animation-duration: 17s;
    }

    .bg-bubbles li:nth-child(3) {
        left: 25%;
        -webkit-animation-delay: 4s;
        animation-delay: 4s;
    }

    .bg-bubbles li:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        -webkit-animation-duration: 22s;
        animation-duration: 22s;
        background-color: rgba(255, 255, 255, 0.25);
    }

    .bg-bubbles li:nth-child(5) {
        left: 70%;
    }

    .bg-bubbles li:nth-child(6) {
        left: 80%;
        width: 120px;
        height: 120px;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        background-color: rgba(255, 255, 255, 0.2);
    }

    .bg-bubbles li:nth-child(7) {
        left: 32%;
        width: 160px;
        height: 160px;
        -webkit-animation-delay: 7s;
        animation-delay: 7s;
    }

    .bg-bubbles li:nth-child(8) {
        left: 55%;
        width: 20px;
        height: 20px;
        -webkit-animation-delay: 15s;
        animation-delay: 15s;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
    }

    .bg-bubbles li:nth-child(9) {
        left: 25%;
        width: 10px;
        height: 10px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-animation-duration: 40s;
        animation-duration: 40s;
        background-color: rgba(255, 255, 255, 0.3);
    }

    .bg-bubbles li:nth-child(10) {
        left: 90%;
        width: 160px;
        height: 160px;
        -webkit-animation-delay: 11s;
        animation-delay: 11s;
    }

    @-webkit-keyframes square {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
        }
    }

    @keyframes square {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }

        100% {
            -webkit-transform: translateY(-700px) rotate(600deg);
            transform: translateY(-700px) rotate(600deg);
        }
    }
</style>
